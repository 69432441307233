<template>
	<div class="pageContainer " style="position: relative; overflow: auto;">
		<el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
			<el-tab-pane label="" name="td">
				<span slot="label">
					<i>学生列表</i>
				</span>
			</el-tab-pane>
		</el-tabs>
		<el-row style="margin-left: 30px; margin-block: 10px">

			<el-input placeholder="请输入关键字" v-model="formData.keyword" class="input-with-select" style="width: 280px;" size="small">
				<el-select v-model="formData.ktype" slot="prepend" placeholder="请选择" style="width: 100px;">
					
					<el-option label="学生姓名" value="stu_name"></el-option>
					<el-option label="学号" value="stu_code"></el-option>
					<el-option label="手机号" value="sch_phone"></el-option>
					<el-option label="企业名称" value="sx_qiye"></el-option>
				</el-select>

			</el-input>


			

			<el-select v-model="formData.year" @change="changeYear" placeholder="请选择" size="small" style="width: 120px; margin-left: 10px;">
				<el-option label="全部" value=""></el-option>
				<el-option v-for="(y,i) in YearList" :label="y.gyear" :value="y.gyear"></el-option>
				
			</el-select>
			
			<el-select v-model="formData.class_name" placeholder="请选择班级" size="small" style="width: 120px; margin-left: 10px;">
				<el-option label="全部" value=""></el-option>
				<el-option v-for="(y,i) in ClassList" :label="y.class_name" :value="y.class_name"></el-option>
				
			</el-select>

			<el-button size="small" icon="el-icon-search" style="margin-left: 10px;" @click="onSearch" plain type="primary">查询</el-button>
			<el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>

			<el-upload style="display: inline-block; margin-left: 20px; " action="" :http-request="uploadTemp"
				:on-success="uploadFiles" :show-file-list="false" name="image">
				<el-button size="small" icon="el-icon-upload2" type="">导入学生</el-button>
			</el-upload>

			<el-button size="small" icon="el-icon-download" type="text">
				<a :href="'./xls/导入学生模板.xls'" target="_blank"
					style="text-decoration: none; color: inherit; margin-right: 20px;">下载学生表模板</a>
			</el-button>
		</el-row>
		<el-table stripe :data="dataList" style="width: 100%; margin-bottom: 20px; color: #000" border size="mini">
			<el-table-column type="index" label="序号" width="80"></el-table-column>
			<el-table-column prop="gyear" label="毕业年份" min-width="80"></el-table-column>
			<el-table-column prop="stu_code" label="学号" min-width="80"></el-table-column>
			<el-table-column prop="stu_name" label="姓名" min-width="80"></el-table-column>
			<el-table-column prop="sch_phone" label="联系电话" min-width="100"></el-table-column>

			<el-table-column prop="class_name" label="班级" min-width="100"></el-table-column>

			<el-table-column prop="email" label="邮箱" min-width="120"></el-table-column>
			<el-table-column prop="sx_qiye" label="实际工作单位名称" min-width="120">

			</el-table-column>
		</el-table>


		<el-dialog title="导入失败学生" :visible.sync="dialogShow" width="800px" :close-on-click-modal="false"
			:destroy-on-close="true">
			<div style="height: 600px; overflow: auto;">
				<div v-for="(stu,idx) in FailList" :key="idx">{{stu}}</div>
			</div>

		</el-dialog>


	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				count: 0,
				current_page: 1,
				per_page: 13,
				activeName: "td",
				dataList: [],
				showSP: false,
				dialogShow:false,
				formData: {
					year: "",
					class_name: "",
					ktype: "stu_name",
					keyword: ""
				},
				spword: "",
				showPrint: false,
				YearList:[],
				ClassList:[],
				FailList:[]

			};
		},
		mounted() {
			
			//this.getlist();
			this.$http.post("/api/njwx_stu_years").then(res=>{
				this.YearList = res.data
				this.formData.year = res.data[0].gyear
				this.getlist()
				this.getClass()
			})
		},
		methods: {
			changeYear(){
				this.getClass()
				
			},
			getClass(){
				this.$http.post("/api/njwx_stu_classes",{year:this.formData.year}).then(res=>{
					this.ClassList = res.data
				})
			},
			getlist() {
				this.$http
					.post("api/njwx_stu_list", this.formData)
					.then((res) => {

						this.dataList = res.data;

					});
			},
			changeTab() {
				this.getlist();
			},
			onSearch() {
				this.getlist();
			},
			onReset() {
				this.formData = {};
				this.getlist();
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.current_page = val;
				this.getlist();
			},
			handleCancel() {},
			printIframe() {
				var iframe = document.getElementById("printPage");
				iframe.contentWindow.focus();
				iframe.contentWindow.print();
			},
			uploadFiles(e) {
				this.$http.post("/api/njwx_import_students",{url:e.src}).then(res=>{
					this.$message.success("导入成功")
					if(res.data.fail.length>0){
						this.FailList = res.data.fail
						this.dialogShow = true
					}


				})
			}
		},
	};
</script>
